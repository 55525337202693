<!-- 交换机 无人机 图像网 -->
<template>
    <div class="onthefull">
        <div class="_server onthefull">
            <!-- 头部 -->
            <div class="_head flexs">
                <div class="flexs chuizhong">
                    <div class="iceones"></div>
                    <div style="font-size:22px">{{ '基站' }}状态情况</div>
                </div>
                <div style="font-size:20px;cursor: pointer;" @click="_serverClose">关闭</div>
            </div>
            <!-- 第一行 -->
            <div class="cpu_state">
                <!-- cpu -->
                <div class="flexs">
                    <div class="_icon one"></div>
                    <div class="wenzi">
                        <div class="fontOne">{{ newestCPU }}<span class="fonts">MB/s</span></div>
                        <div class="fontTwo">网络</div>
                    </div>
                </div>
                <!-- 内存 -->
                <div class="flexs">
                    <div class="_icon two"></div>
                    <div class="wenzi">
                        <div class="fontOne">{{ (Number(newstflow) / 1024).toFixed(2) }}<span class="fonts">MB/s</span></div>
                        <div class="fontTwo">供网</div>
                    </div>
                </div>
            </div>
            <!-- 第二行 -->
            <div class="server_state">
                <div class="fangkuai">
                    <div>镜</div>
                    <div>像</div>
                    <div>服</div>
                    <div>务</div>
                </div>
                <div class="tabulation">
                    <div class="flexs hang tou">
                        <div>名称</div>
                        <div>状态</div>
                        <div>内存占用</div>
                        <div>内存占比</div>
                        <div>CPU占比</div>
                    </div>
                    <div class="flexs hang shen">
                        <div>{{ 'Redis' }}</div>
                        <div>{{ 'RUN' }}</div>
                        <div>{{ '3.32' }}GB</div>
                        <div>{{ '20' }}%</div>
                        <div>{{ '20' }}%</div>
                    </div>
                </div>
            </div>
            <!-- echarts -->
            <div class="diagram flexs">
                <!-- cpu图表 -->
                <div class="flexs _height">
                    <div class="echarts_style flexs">
                        <div class="_icons _one"></div>
                        <div class="_font">
                            <div>网络</div>
                            <div>状态</div>
                        </div>
                    </div>
                    <div id="CPU"></div>
                </div>
                <!-- 基站图表 -->
                <div class="flexs _height">
                    <div class="echarts_style flexs">
                        <div class="_icons _two"></div>
                        <div class="_font">
                            <div>供网</div>
                            <div>状态</div>
                        </div>
                    </div>
                    <div id="memory"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        liuchu: { //cpu数据
            type: Array
        },
        flow: { // 流量数据

        }
    },
    data() {
        return {
            information: null,

            times: [],//图标里x轴时间(echarts)cpu
            times_: [],//图标hover下的时间(echarts)cpu
            Cpushuju: [],//cpu百分比数据(echarts)cpu
            newestCPU: null,//cpu最新数据

            flowtime: null,///图标里x轴时间(echarts)流量
            flowtime_: null,//图标hover下的时间(echarts)流量
            flowshuju: [],//流量百分比数据(echarts)流量
            newstflow: null,// 流量最新数据
        }
    },
    watch: {
        interchangerData: {
            // 实例尚未创建所以第一次点击没有效果
            // 解决方案:立即执行一遍immediate: true,
            immediate: true,
            handler(vla, old) {
                this.information = vla
            }
        },
        liuchu:function(vla,old){
            this.information = vla
                this.times = []
                this.Cpushuju = []
                this.times_ = []
                // cpu数据摘出
                this.zCPU(vla)
                this.jutishuju(vla)
                this.cpu_echarts()
        },
        flow: function (vla, old) {
            // console.log(vla, '触发了吗');
            this.flowtime = []
            this.flowtime_ = []
            this.flowshuju = []
            // 流量数据摘出
            this.flux_time(vla)
            this.flux_data(vla)
            this.flux_echarts()
        },
        deep: true,

    },
    mounted() {

    },
    methods: {
           // 时间戳转换具体时间
           _time(time = +new Date()) {
            // console.log(time);
            var date = new Date(time + 8 * 3600 * 1000);
            return date.toJSON().substr(0, 19).replace('T', ' ');
        },
        // cpu图表
         cpu_echarts() {
            //
            let a = 0
            let b = false
            const myChart = this.$echarts.init(document.getElementById("CPU"));
            let option = {
                backgroundColor: 'transparent',
                title: {
                    text: '',
                    textStyle: {
                        align: 'center',
                        color: '#fff',
                        fontSize: 20,
                    },
                    top: '5%',
                    left: 'center',
                },
                tooltip: {
                    show: true,
                    trigger: "item",
                    showContent: true,//关闭原有样式
                    padding: [
                        0, // 上
                        0, // 右
                        0, // 下
                        0, // 左
                    ],
                    formatter: (name) => {
                        // 这里forEach循环就不创建样式,所以改成这样
                        if (name.name == this.times[0]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[0]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'minio'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[0]}MB/s</div></div></div></div>`
                        } else if (name.name == this.times[1]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[1]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'minio'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[1]}MB/s</div></div></div></div>`
                        } else if (name.name == this.times[2]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[2]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'minio'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[2]}MB/s</div></div></div></div>`
                        } else if (name.name == this.times[3]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[3]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'minio'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[3]}MB/s</div></div></div></div>`
                        } else if (name.name == this.times[4]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[4]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'minio'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[4]}MB/s</div></div></div></div>`
                        } else if (name.name == this.times[5]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.times_[5]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #24EC40;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'minio'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.Cpushuju[5]}MB/s</div></div></div></div>`
                        }

                    }
                },
                grid: {
                    top: '15%',
                    left: '15%',
                    right: '5%',
                    bottom: '20%',
                    // containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#C7E5E7',
                        },
                        symbol: ['none', 'arrow'], // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
                        symbolSize: [5, 5], // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
                    },
                    splitArea: {
                        // show: true,
                        color: '#f00',
                        lineStyle: {
                            color: '#f00'
                        },
                    },
                    axisLabel: {
                        color: '#fff',
                        fontSize: '14',
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: { //隐藏刻度线
                        "show": false
                    },
                    boundaryGap: true,
                    data: this.times,

                }],

                yAxis: [{
                    type: 'value',
                    min: 0,
                    // max: 140,
                    splitNumber: 4,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.3)'
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        // formatter: function (value) {
                        //     if (b == false) {
                        //         let c = a
                        //         a = a + 1
                        //         b = true
                        //         return c + '%'
                        //     } else {
                        //         a = a * 2
                        //         return a + '%'
                        //     }
                        // },
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    // data: ['A', 'B', 'C', 'D', 'E', 'F'],
                }],
                series: [
                    {
                        name: 'bar',
                        type: 'line',
                        smooth: false, //是否平滑
                        showAllSymbol: true,
                        // symbol: 'image://./static/images/guang-circle.png',
                        symbol: 'circle',
                        symbolSize: 8,//内圆大小
                        emphasis: {
                            itemStyle: {
                                borderWidth: 20,
                            }
                        },
                        lineStyle: {
                            normal: {
                                color: "#BDCFD2",//线条颜色
                                shadowColor: 'rgba(0, 0, 0, .3)',
                                shadowBlur: 0,
                                shadowOffsetY: 5,
                                shadowOffsetX: 5,
                            },
                        },
                        label: {
                            show: false,
                            position: 'top',
                            textStyle: {
                                color: '#00ca95',
                            }
                        },

                        itemStyle: {
                            color: "#24EC40",//内圆颜色
                            borderColor: "rgb(177,220,227,.34)",//外圆颜色
                            borderWidth: '6',//外圆大小,请加上内圆
                            shadowColor: 'rgba(0, 0, 0, .3)',
                            shadowBlur: 0,
                            shadowOffsetY: 112,
                            shadowOffsetX: 1112,
                        },
                        data: this.Cpushuju,
                    },
                ]
            };
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
            window.addEventListener("resize", function () {
                myChart.resize();
            });
        },
        // 流量图表
        flux_echarts() {
            //
            let a = 0
            let b = false
            const myChart = this.$echarts.init(document.getElementById("memory"));
            let option = {
                backgroundColor: 'transparent',
                title: {
                    text: '',
                    textStyle: {
                        align: 'center',
                        color: '#fff',
                        fontSize: 20,
                    },
                    top: '5%',
                    left: 'center',
                },
                tooltip: {
                    show: true,
                    trigger: "item",
                    showContent: true,//关闭原有样式
                    padding: [
                        0, // 上
                        0, // 右
                        0, // 下
                        0, // 左
                    ],
                    formatter: (name) => {

                        if (name.name == this.flowtime[0]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[0]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'cadvisor'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[0]}B</div></div></div></div>`
                        } else if (name.name == this.flowtime[1]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[1]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'cadvisor'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[1]}B</div></div></div></div>`
                        } else if (name.name == this.flowtime[2]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[2]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'cadvisor'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[2]}B</div></div></div></div>`
                        } else if (name.name == this.flowtime[3]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[3]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'cadvisor'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[3]}B</div></div></div></div>`
                        } else if (name.name == this.flowtime[4]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[4]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'cadvisor'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[4]}B</div></div></div></div>`
                        } else if (name.name == this.flowtime[5]) {
                            return `<div style="width: 220px;height: 117px;background: rgba(0,0,0,0.7);backdrop-filter: blur(4px);z-index:100;padding:14px 14px 23px 14px;"><div style="font-size: 16px;font-family: D-DIN, D;font-weight: normal;color: #FFFFFF;text-align:center;">${this.flowtime_[5]}</div><div style="width:1px;height:6px;"></div><div style="width:100%;display:flex;justify-content:space-between;align-items:center;"><div style="display:flex;height:100%;align-items:center;"><div style="width: 6px;height: 6px;background: #00EAFF;"></div><div style="width:6px;height:1px;"></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${'cadvisor'}</div></div><div style="font-size: 14px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;font-weight: 400;color: #FFFFFF;">${this.flowshuju[5]}B</div></div></div></div>`
                        }
                    }
                },
                grid: {
                    top: '15%',
                    left: '15%',
                    right: '5%',
                    bottom: '20%',
                    // containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#C7E5E7',
                        },
                        symbol: ['none', 'arrow'], // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
                        symbolSize: [5, 5], // 轴线两端箭头大小，数值一表示宽度，数值二表示高度
                    },
                    splitArea: {
                        // show: true,
                        color: '#f00',
                        lineStyle: {
                            color: '#f00'
                        },
                    },
                    axisLabel: {
                        color: '#fff',
                        fontSize: '14',
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: { //隐藏刻度线
                        "show": false
                    },
                    boundaryGap: true,
                    data: this.flowtime,

                }],

                yAxis: [{
                    type: 'value',
                    min: 0,
                    // max: 140,
                    splitNumber: 4,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.3)'
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        // formatter: function (value) {
                        //     if (b == false) {
                        //         let c = a
                        //         a = a + 1
                        //         b = true
                        //         return c + '%'
                        //     } else {
                        //         a = a * 2
                        //         return a + '%'
                        //     }
                        // },
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    // data: ['A', 'B', 'C', 'D', 'E', 'F'],
                }],
                series: [
                    {
                        name: 'bar',
                        type: 'line',
                        smooth: false, //是否平滑
                        showAllSymbol: true,
                        // symbol: 'image://./static/images/guang-circle.png',
                        symbol: 'circle',
                        symbolSize: 8,//内圆大小
                        emphasis: {
                            itemStyle: {
                                borderWidth: 20,
                            }
                        },
                        lineStyle: {
                            normal: {
                                color: "#BDCFD2",//线条颜色
                                shadowColor: 'rgba(0, 0, 0, .3)',
                                shadowBlur: 0,
                                shadowOffsetY: 5,
                                shadowOffsetX: 5,
                            },
                        },
                        label: {
                            show: false,
                            position: 'top',
                            textStyle: {
                                color: '#00EAFF',
                            }
                        },

                        itemStyle: {
                            color: "#00EAFF",//内圆颜色
                            borderColor: "rgb(177,220,227,.34)",//外圆颜色
                            borderWidth: '6',//外圆大小,请加上内圆
                            shadowColor: 'rgba(0, 0, 0, .3)',
                            shadowBlur: 0,
                            shadowOffsetY: 112,
                            shadowOffsetX: 1112,
                        },
                        data: this.flowshuju,
                    },
                ]
            };
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
            window.addEventListener("resize", function () {
                myChart.resize();
            });
        },
        // cpu时间
        zCPU(vla) {
            let a = parseInt((vla.length - 1) * 0.2)
            let b = parseInt((vla.length - 1) * 0.4)
            let c = parseInt((vla.length - 1) * 0.6)
            let d = parseInt((vla.length - 1) * 0.8)
            // 开始时间
            let one = vla[0][0]
            let _one = this._time(one * 1000).slice(10, 16)
            this.times.push(_one)
            this.times_.push(this._time(one * 1000))
            // 数组长度20%的时间
            let two = vla[a][0]
            let _two = this._time(two * 1000).slice(10, 16)
            this.times.push(_two)
            this.times_.push(this._time(two * 1000))
            // 数组长度40%的时间
            let three = vla[b][0]
            let _three = this._time(three * 1000).slice(10, 16)
            this.times.push(_three)
            this.times_.push(this._time(three * 1000))
            // 数组长度60%的时间
            let four = vla[c][0]
            let _four = this._time(four * 1000).slice(10, 16)
            this.times.push(_four)
            this.times_.push(this._time(four * 1000))
            // 数组长度80%的时间
            let five = vla[d][0]
            let _five = this._time(five * 1000).slice(10, 16)
            this.times.push(_five)
            this.times_.push(this._time(five * 1000))
            // 当前时间
            let six = vla[vla.length - 1][0]
            let _six = this._time(six * 1000).slice(10, 16)
            this.times.push(_six)
            this.times_.push(this._time(six * 1000))
            // console.log(this.times_, 'zCPU');
        },
         // CPU具体数据
         jutishuju(vla) {
            let a = parseInt((vla.length - 1) * 0.2)
            let b = parseInt((vla.length - 1) * 0.4)
            let c = parseInt((vla.length - 1) * 0.6)
            let d = parseInt((vla.length - 1) * 0.8)
            // 数组第一条数据
            let one = vla[0][1]
            let _one = Number(one).toFixed(2)
            this.Cpushuju.push(_one)
            // 数组长度20%的数据
            let two = vla[a][1]
            let _two = Number(two).toFixed(2)
            this.Cpushuju.push(_two)
            // 数组长度40%的数据
            let three = vla[b][1]
            let _three = Number(three).toFixed(2)
            this.Cpushuju.push(_three)
            // 数组长度60%的数据
            let four = vla[c][1]
            let _four = Number(four).toFixed(2)
            this.Cpushuju.push(_four)
            // 数组长度80%的数据
            let five = vla[d][1]
            let _five = Number(five).toFixed(2)
            this.Cpushuju.push(_five)
            // 当前时间数据
            let six = vla[vla.length - 1][1]
            let _six = Number(six).toFixed(2)
            this.newestCPU = _six
            this.Cpushuju.push(_six)
        },
            // 流量时间
            flux_time(vla) {
            let a = parseInt((vla.length - 1) * 0.2)
            let b = parseInt((vla.length - 1) * 0.4)
            let c = parseInt((vla.length - 1) * 0.6)
            let d = parseInt((vla.length - 1) * 0.8)
            // 开始时间
            let one = vla[0][0]
            let _one = this._time(one * 1000).slice(10, 16)
            this.flowtime.push(_one)
            this.flowtime_.push(this._time(one * 1000))
            // 数组长度20%的时间
            let two = vla[a][0]
            let _two = this._time(two * 1000).slice(10, 16)
            this.flowtime.push(_two)
            this.flowtime_.push(this._time(two * 1000))
            // 数组长度40%的时间
            let three = vla[b][0]
            let _three = this._time(three * 1000).slice(10, 16)
            this.flowtime.push(_three)
            this.flowtime_.push(this._time(three * 1000))
            // 数组长度60%的时间
            let four = vla[c][0]
            let _four = this._time(four * 1000).slice(10, 16)
            this.flowtime.push(_four)
            this.flowtime_.push(this._time(four * 1000))
            // 数组长度80%的时间
            let five = vla[d][0]
            let _five = this._time(five * 1000).slice(10, 16)
            this.flowtime.push(_five)
            this.flowtime_.push(this._time(five * 1000))
            // 当前时间
            let six = vla[vla.length - 1][0]
            let _six = this._time(six * 1000).slice(10, 16)
            this.flowtime.push(_six)
            this.flowtime_.push(this._time(six * 1000))
        },
        // 流量具体数据
        flux_data(vla) {
            let a = parseInt((vla.length - 1) * 0.2)
            let b = parseInt((vla.length - 1) * 0.4)
            let c = parseInt((vla.length - 1) * 0.6)
            let d = parseInt((vla.length - 1) * 0.8)
            // 数组第一条数据
            let one = vla[0][1]
            let _one = Number(one).toFixed(2)
            this.flowshuju.push(_one)
            // 数组长度20%的数据
            let two = vla[a][1]
            let _two = Number(two).toFixed(2)
            this.flowshuju.push(_two)
            // 数组长度40%的数据
            let three = vla[b][1]
            let _three = Number(three).toFixed(2)
            this.flowshuju.push(_three)
            // 数组长度60%的数据
            let four = vla[c][1]
            let _four = Number(four).toFixed(2)
            this.flowshuju.push(_four)
            // 数组长度80%的数据
            let five = vla[d][1]
            let _five = Number(five).toFixed(2)
            this.flowshuju.push(_five)
            // 当前时间数据
            let six = vla[vla.length - 1][1]
            let _six = Number(six).toFixed(2)
            this.newstflow = _six
            this.flowshuju.push(_six)
        },
        // 关闭弹窗和定时器
        _serverClose() {
            // 调用父组件的serverClose方法
            this.$emit('serverClose')
        }
    },
    beforedestroy() {
        this.$emit('serverClose')
    }
}
</script>

<style lang="scss" scoped>
.onthefull {
    width: 100%;
    height: 100%
}

._server {
    background-image: url('~@/assets/server/dakuang.png');
    background-size: 100% 100%;
    backdrop-filter: blur(4px);
}

.flexs {
    display: flex;
}

// 头部
._head {
    width: 100%;
    height: 30px;
    justify-content: space-between;
    padding: 0 14px 0 14px;
    align-items: center;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 29px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5), 0px 0px 12px rgba(19, 145, 241, 0.5);
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 36%, #54ACFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-sizing: border-box;
}

.chuizhong {
    height: 100%;
    align-items: center;
}

.iceones {
    width: 29px;
    height: 20px;
    background-size: 100% 100%;
    background-image: url('~@/assets/server/leftJt.png');
    margin: 0 10px 0 0;
}

// 第一行状态栏
.cpu_state {
    width: 100%;
    height: 78px;
    display: flex;
    justify-content: space-around;
    padding: 0 34px 0 34px;
    box-sizing: border-box;
    align-items: center;

    ._icon {
        width: 52px;
        height: 52px;
        background-size: 100% 100%;
    }

    .fonts {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
    }

    .one {
        background-image: url('~@/assets/server/wangluo.png');
    }

    .two {
        background-image: url('~@/assets/server/jizahn.png');
    }

    .wenzi {
        margin: 0 0 0 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .fontOne {
        font-size: 28px;
        font-family: D-DIN-Bold, D-DIN;
        font-weight: bold;
        color: #FFFFFF;
    }

    .fontTwo {
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
    }
}

// 第二行
.server_state {
    width: 100%;
    height: 116px;
    display: flex;
    align-items: center;

    .fangkuai {
        width: 51px;
        height: 90px;
        background: rgba(21, 95, 192, 0.1);
        border: 1px solid;
        border-image: linear-gradient(270deg, rgba(171, 210, 234, 1), rgba(115, 166, 209, 0)) 1 1;
        margin: 0 23px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: 14px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
    }

    .tabulation {
        width: 466px;
        height: 80px;
        display: flex;
        flex-direction: column;
        padding: 0 25px 0 25px;
        box-sizing: border-box;

        .hang {
            flex: 1;
            justify-content: space-between;
            align-items: center;

            div {
                flex: 1;
                text-align: center;
            }
        }

        .tou {
            background: rgba(138, 152, 177, 0.18);
            font-size: 16px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
        }

        .shen {
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}

.diagram {
    width: 100%;
    height: 385px;
    padding: 23px 24px 24px 24px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;

    .echarts_style {
        height: 100%;
        flex-direction: column;
        align-items: center;
        margin: 0 16px 0 0;
        justify-content: center;

        ._icons {
            width: 52px;
            height: 52px;
            background-size: 100% 100%;
            margin: 0 0 6px 0;
        }

        ._font {
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
        }

        ._one {
            background-image: url('~@/assets/server/xinpian.png');
        }

        ._two {
            background-image: url('~@/assets/server/jizahn.png');
        }

    }
}

#CPU {
    flex: 1;
    height: 100%;
    pointer-events: auto;
}

#memory {
    flex: 1;
    height: 100%;
}

._height {
    height: 160px;
}
</style>
